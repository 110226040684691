const dNone = 'd-none';

const init = elem => {
    const instance = {
        elem: elem,
        $elem: $(elem)
    };    
    
    instance.$value = instance.$elem.find('.input-guests-value');
    instance.$dropdown = instance.$elem.closest('.dropdown');
    instance.$dropdownMenu = instance.$dropdown.find('.dropdown-menu');
    instance.$menu = instance.$dropdown.find('.dropdown-menu-popover');
    instance.$rooms = instance.$dropdown.find('.room');
    instance.$hidden = instance.$dropdown.find('input[type="hidden"]');
    instance.max = instance.$elem.attr('data-max');

    // events
    // open
    instance.$dropdown.on('show.bs.dropdown', e => {
        instance.values = instance.$hidden[0].value || '2,0';
        instance.$menu.find('.input-rooms').val(instance.values.split(':').length);
        setRooms(instance);
    });

    instance.$dropdownMenu.on('click.bs.dropdown', e => {
        e.stopPropagation();
        e.preventDefault();
    });

    // rooms change - show/hide multiple rooms
    instance.$menu.find('.input-rooms').on('change', e => {
        let rooms = +e.target.value - 1;
        let values = instance.values.split(':');
        for ( let i = 0; i < 4; i++ ) {
            if ( i > rooms ) { // remove extra rooms
                values[i] = '';
            }
            else if ( !values[i] ) { // add rooms
                values[i] = '2,0';
            }
        }
        instance.values = values.join(':');
        setRooms(instance);
    });

    // adults change
    instance.$menu.on('change', '.input-adults', e => {
        e.stopPropagation();
        const $room = $(e.target).closest('.room');
        const index = +$room.attr('data-index');
        let values = instance.values.split(':');
        let value = values[index].split(',');
        value[0] = +e.target.value;

        if ( instance.max ) {
            let visibleChildren = instance.max - value[0];
            if ( value[1] > visibleChildren ) {
                value[1] = visibleChildren;
            }
            $room.find('.input-children').val(value[1]).find('option').each((i, option) => {
                $(option).toggleClass('d-none', i > visibleChildren);
            });
            value = value.slice(0, 2 + value[1]);
            setRoom(instance, index, value.join(','));
        }
        values[index] = value.join(',');
        instance.values = values.join(':');
    });
    // children change - show/hide this room childs
    instance.$menu.on('change', '.input-children', e => {
        e.stopPropagation();
        const children = +e.target.value;
        const $room = $(e.target).closest('.room');
        let value = instance.values.split(':')[$room.attr('data-index')].split(',');
        value[1] = children;

        if ( instance.max ) {
            let visibleAdults = instance.max - children;
            if ( value[0] > visibleAdults ) {
                value[0] = visibleAdults;
            }
        }

        value = value.slice(0, 2 + children).join(',');
        setRoom(instance, $room.attr('data-index'), value);
    });
    // allow only numbers on childs
    instance.$menu.on('keydown', '.input-child', e => {
        e.stopPropagation();
        // prevent not numbers
        if ( !/Arrow|Backspace|Delete|Tab/.test(e.key) && /\D/.test(e.key) ) {
            e.preventDefault();
        }
        let value = e.target.value;
        let selection = e.target.selectionEnd - e.target.selectionStart;
        if ( selection ) {
            // if input is "fully selected" pass, otherwise remove selected number from value
            if ( selection == value.length ) {
                return;
            } else {
                if ( e.target.selectionStart == 0 ) {
                    value = e.key + value.slice(1);
                } else {
                    value = value[0] + e.key;
                }
            }
        } else {
            if ( e.target.selectionStart ) {
                value = value + e.key;
            } else {
                value = e.key + value;
            }
        }
        // if possible value greater than 17 - prevent key
        if ( value > 17 ) {
            e.preventDefault();
        }
    });
    // stop propagation on childs
    instance.$menu.on('change', '.input-child', e => e.stopPropagation());

    
    // prevent paste on childs
    instance.$menu.on('paste', '.input-child', e => e.preventDefault());
    // apply button set element and trigger change
    instance.$menu.on('click', 'button', e => {
        setValues(instance);
    });
    // elem change
    instance.$hidden.on('change', e => elemChange(instance));
    // child focus select value
    instance.$menu.on('focus', '.input-child', e => e.target.select());
    // init set
    elemChange(instance);
};

const elemChange = instance => {
    // set initial data
    instance.values = instance.$hidden[0].value || '2,0';
    // set element guests number
    let guests = 0;
    instance.values.split(':').map(value => {
        value = value.split(',');
        guests += (+value[0] + +value[1]);
    });
    instance.$value.text(guests);
};

const setRooms = instance => {
    instance.values.split(':').forEach((value, i) => {
        setRoom(instance, i, value);
    });
};

const setRoom = (instance, index, value) => {
    const $room = instance.$rooms.eq(index).toggleClass(dNone, !value);
    const $adults = $room.find('.input-adults');
    const $children = $room.find('.input-children');
    const $childrens = $room.find('.children-wrap');
    const $childs = $childrens.find('.input-child');
    
    value = value.split(',');
    $adults.val(value.shift());
    const children = +value.shift();
    $room.find('.input-children').val(children);

    if ( children ) {
        $childrens.removeClass(dNone);
        $childs.each((i, child) => {
            if ( value[i] || i + 1 <= children ) {
                child.value = value[i] || 0;
                child.classList.remove('d-none');
            } else {
                child.classList.add('d-none');
            }
        });
    } else {
        $childrens.addClass(dNone);
    }

    if ( instance.max && ! instance.maxResolved ) {
        $adults.find('option:gt(' + (instance.max - 1) + ')').remove();

        $children.find('option:gt(' + (instance.max - $adults.val()) + ')').toggleClass('d-none', true);
        instance.maxResolved = true;
    }
};

const setValues = instance => {
    let guests = 0;
    let values = $.map(instance.$rooms, room => {
        if ( !room.classList.contains(dNone) ) {
            const $room = $(room);
            const value = [+$room.find('.input-adults').val(), +$room.find('.input-children').val()];
            guests += (value[0] + value[1]);
            $room.find('.input-child').map((i, child) => {
                i < value[1] && value.push(child.value || 0);
            });
            return value.join(',');
        }
    });
    instance.$value.text(guests);
    instance.$hidden[0].value = values.join(':');
    instance.$hidden.change();
    instance.$elem.dropdown('hide');
};

module.exports = {
    init: init,
    apply () {
        $('.input-guests').each((i, elem) => {
            init(elem);
        });
    }
};