import functions from "./functions.js";
import Slider from './Slider.js';

const list = {
    $form: null,
    form: null,
    params: {
        objectTypeId: null,
        tagCategoryId: null,
        language: functions.getLang(),
    },
    defaults: {
        hasArrival: 2,
        arrivalDateFrom: null,
    },
    $listItems: null,
    listItems: null,
    hiddenParams: ['catalogueid', 'language', 'perPage', 'tagCategoryId', 'hasArrival', 'objectTypeId'],
    init() {

        list.$form = $('.search-form');
        list.form = list.$form[0];

        if(! list.form) {
            $('[id^=list-slider]').each(function () {
                new Slider(this, {
                    fade: true,
                    loop: true,
                    arrows: true
                });
            });
            return;
        }

        list.params.tagCategoryId = list.$form.attr('data-tagcategoryid');

        list.getParams();
        functions.setFormData(list.form, list.params);

        list.$listItems = $('.list-items');
        list.listItems = $('.list-items')[0];

        if(list.listItems) {
            list.find();
        }

        list.$form.on('submit', e => {
            e.preventDefault();

            if(list.listItems) {
                functions.setUrlData(list.getFormData(list.$form), true);
                list.getParams();
    
                functions.setFormData(list.form, list.params);
                list.find();
            } else {
                location = location.href + list.$form.attr('action') + '#' + $.param(list.prepareUrlData($(e.target)));
            }

        });

    },
    // get URL params and merge with defaults - (ex. things changed after init search)
    getParams() {
        list.params = $.extend(list.params, list.defaults, functions.getUrlData(true));
    },
    getFormData($form) {
        let formData = functions.getFormData($form || list.$form);
        // do not send same values as defaults
        $.map(formData, (val, key) => {
            if (list.defaults[key] == val) {
                delete formData[key];
            }
        });

        return formData;
    },
    prepareUrlData($form) {
        let o = {};
        $.map(list.getFormData($form), (val, key) => {
            if (list.hiddenParams.indexOf(key) == -1) {
                o[key] = val;
            }
        });
        return o;
    },
    find() {

        $.get('/services/list/', list.params).then(response => {
            response = JSON.parse(response);
            $('.list-items').html(response.html);
            $('[id^=list-slider]').each(function () {
                new Slider(this, {
                    fade: true,
                    loop: true,
                    arrows: true
                });
            });

            $('.departure-date').on('click', e => {
                e.preventDefault();
                
                let $target = $(e.target);
                if(! $(e.target).hasClass('departure-date')) {
                    $target = $(e.target).parents('.departure-date');
                }
                
                location = $target.attr('data-url'); 
            });

        });
    },
}

$(() => {
    list.init();
});