import functions from "./functions.js";

const review = {
    init() {
        let $form = $('.review-form');
        if ( ! $form[0]) return;

        let $msg = $('.review-msg');
        let data = $('[data-review-data]').data('review-data');
        data.items = {};
        data.language = functions.getLang();

        $form.find("[data-review-item-id]").each((index, item) => {
            let id = $(item).attr('data-review-item-id');
            let $input = $(item).find('input[name="rating-' + id + '"]');
            data.items[id] = {
                unitId: id
            };

            $(item).find(".rating .star").on('click', function (e) {
                let $star = $(e.currentTarget);
                let value = $star.attr('data-value');
                $input.val(value).attr(value);
            });
        });


        $form.on('submit', e => {

            e.preventDefault();

            $.each(data.items, function (id, item) {
                data.items[id].rating = $('input[name="rating-' + id + '"]').val();
                data.items[id].review = $('textarea[name="review-' + id + '"]').val();
            });

            grecaptcha.ready(function() {
                grecaptcha.execute('6Lf9hl0aAAAAAL-eQLlwbHIHJ2Ha2zIbr6Q1MSNB', {action: 'review'}).then(function(token) {
                    data.gRecaptchaToken = token;

                    $.post('/services/review/', data).then(response => {
                        $msg.removeClass('d-none');
                        $msg.find('.review-success').toggleClass('d-none', ! response.status );
                        $msg.find('.review-error').toggleClass('d-none', response.status );
                        $form.find("button[type='submit']").addClass('disabled');
                    });
                });
            });
        });
    }
}



$(() => {
    review.init();
});