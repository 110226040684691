import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        RichText,
        InspectorControls,
        MediaUpload,
    } = window.wp.editor;
    
    const { 
        PanelBody, 
        IconButton, 
        TextControl
    } = window.wp.components;

    registerBlockType('custom-gutenberg/card-video', {
        // built-in attributes
        title: 'Card Video',
        description: 'Custom Card Video',
        icon: '',
        category: 'custom-cards',
        example: {},

        // custom attributes
        attributes: {
            imageUrl: {
                type: 'string',
                default: ''
            }, 
            videoUrl: {
                type: 'string',
                default: ''
            }, 
            title: {
                type: 'string',
                default: ''
            },
            subtitle: {
                type: 'string',
                default: ''
            },
            duration: {
                type: 'string',
                default: ''
            },
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                videoUrl,
                imageUrl,
                title,
                subtitle,
                duration,
                classes
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onSelectImage (imageUrl) {
                setAttributes({imageUrl: imageUrl.sizes.full.url});
            }

            function onVideoChange (videoUrl) {
                setAttributes({videoUrl: videoUrl});
            }

            function onTitleChange(title) {
                setAttributes({title: title});
            }

            function onSubtitleChange(subtitle) {
                setAttributes({subtitle: subtitle});
            }

            function onDurationChange(duration) {
                setAttributes({duration: duration});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ imageUrl } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                        <br />
                        <br />
                        <p><strong>Video Url:</strong></p>
                        <TextControl value={ videoUrl } onChange={ onVideoChange }></TextControl>
                        <br />
                        <p><strong>Subtitle</strong></p>
                        <TextControl value={ subtitle } onChange={ onSubtitleChange }></TextControl>
                        <br />
                        <p><strong>Title</strong></p>
                        <TextControl value={ title } onChange={ onTitleChange }></TextControl>
                        <br />
                        <p><strong>Duration</strong></p>
                        <TextControl value={ duration } onChange={ onDurationChange }></TextControl>
                    </PanelBody>
                </InspectorControls>
                ,
                <a className={ "card card-video shadow bg-dark " + (classes || '') }>
                    <div className="cover-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                    <div className="card-body">
                        <div className="card-video-play">
                            <img src="/wp-content/uploads/play-video.svg" />
                        </div>
                        <div className="card-video-content">
                            <h4 className="font-weight-normal text-uppercase mb-0">{ subtitle }</h4>
                            <h3 className="mb-0">{ title }</h3>
                            <div className="lead">
                                <div className="badge badge-light">
                                { duration }
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            ]
            );
        },

        save({ attributes }) {

            const {
                videoUrl,
                imageUrl,
                title,
                subtitle,
                duration,
                classes
            } = attributes;

            return (
                <a data-src={ videoUrl } className={ "card card-video shadow bg-dark " + (classes || '') } data-video-gallery>
                    <div className="lozad cover-image" data-background-image={ imageUrl }></div>
                    <div className="card-body">
                        <div className="card-video-play">
                            <img src="/wp-content/uploads/play-video.svg" />
                        </div>
                        <div className="card-video-content">
                            <h4 className="font-weight-normal text-uppercase mb-0">{ subtitle }</h4>
                            <h3 className="mb-0">{ title }</h3>
                            <div className="lead">
                                <div className="badge badge-light">
                                { duration }
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            );
        },
    });
}