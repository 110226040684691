import functions from "./functions.js";
import Slider from './Slider.js';

const onboard = {
    $form: null,
    form: null,
    lang: 'en',
    url: '/en/onboard/',
    currencyIdTo: 1,
    init() {
        onboard.$form = $('#form-lang');
        onboard.form = onboard.$form[0];

        const $onboardLanding = $('.page-type-onboard:has(.bg-lang)');
        if($onboardLanding[0]) {
            $onboardLanding.addClass('pt-0');
        }

        if($('.page-type-onboard:has(#itinerary)')[0]) {
            $('.itinerary-slider').each(function (i) { 
                new Slider(this, {
                    fade: true,
                    loop: true,
                    arrows: true
                });
            });
        }

        if(! onboard.form) return;
        onboard.$form.on('submit', e => {
            e.preventDefault();
            const $target = $(e.target);
            const formData = functions.getFormData($target);
            onboard.url = formData.url;
            onboard.currencyIdTo = formData.currencyIdTo;
            functions.setCookie('currencyIdTo', onboard.currencyIdTo);
            location = onboard.url;
        });
    }
};

$(() => {
    onboard.init();
});