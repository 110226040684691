import functions from "./functions.js";
import Slider from './Slider.js';

$(() => {
    details.init();
});

const details = {
    units: null,
    params: {
        pricelistItemId: null,
        objectGroupId: null,
        objectTypeId: null,
        objectId: null,
        unitId: null,
        dateFrom: null,
        dateTo: null,
        guests: '2,0,0'
    },
    $form: null,
    form: null,
    cart: [],
    charterBook: null,
    init() {
        
        if(! $('.page-type-details')[0]) {
            return;
        }

        const charterBook = this.charterBook = $('[data-charterBook]').length;

        // details slider init
        $('#details-slider').each(function () {
            new Slider(this, {
                fade: true,
                loop: true,
                arrows: true
            });
        });

        if($('.reviews-more')[0]) {
            const $button = $('.reviews-more');
            const total = +$button.data('total');
            const perPage = 5;

            $button.on('click', function (e) {
                let page = +$button.data('page') + 1;
                let pageTotal = Math.round(+$button.data('total') / perPage);
                let showing = perPage * page;

                $.post('/services/reviews/', { language : details.lang, perPage : perPage, page: page, objectId: $button.data('objectid') }).then(response => {
                    if (response.status == true) {

                        $button.data('page', page);
                        if (pageTotal <= page) $button.attr('disabled', 'disabled');

                        $(".reviews-list").append(response.html);
                        $(".reviews-page-total").html(showing > total ? total : showing);
                    }
                });
            });

            return;
        }

        // itinerary sliders init
        $('.itinerary-slider:visible').each(function (i) {
            new Slider(this, {
                fade: true,
                loop: true,
                arrows: true
            });
        });
        $('[id^=allItinerary]').on('shown.bs.collapse', e => {
            $('.show-itinerary').addClass('d-none');
            $('.itinerary-slider:not(.keen-slider__created)').each(function (i) { 
                new Slider(this, {
                    fade: true,
                    loop: true,
                    arrows: true
                });
            });
        });
        $('[id^=allItinerary]').on('hide.bs.collapse', e => {
            $('.show-itinerary').removeClass('d-none');
        });

        // unit sliders init
        $('.unit-slider').each(function (i) {
            new Slider(this, {
                fade: true,
                loop: true,
                arrows: true
            });
        });

        details.getParams();
        
        /*if(!details.params.dateFrom) {
            const $select = $('select[name=dateFrom]');
            if($select[0]) {
                const $options = $select.find('option');
                const $option = $($options[0]);
                details.params.dateFrom = $option.val();
                functions.setUrlData({dateFrom: details.params.dateFrom}, false, true);
            }
        }*/

        details.$form = $('form');
        details.form = details.$form[0];

        details.params.exchangeRate = $('[data-exchangerate]').attr('data-exchangerate');

        if($('.booking-excursion[data-pricelistitemid]')[0]) {
            $('.booking-excursion[data-pricelistitemid]').each(function (index, item) {
                details.params.objectGroupId = $(item).attr('data-objectgroupid');
                details.params.pricelistItemId = $(item).attr('data-pricelistitemid');
                details.params.objectTypeId = $(item).attr('data-objecttypeid');
                details.params.objectId = $(item).attr('data-objectid');
                details.params.dateFrom = $(item).attr('data-datefrom');
                details.params.dateTo = $(item).attr('data-dateto');

                details.getCalculation();
            });

        } else {
            //functions.setFormData(details.form, details.params);

            const $bookingCalendar = $('#booking-calendar-slider');

            if($bookingCalendar[0]) {
                const length = $bookingCalendar.find('li').length;
                const perView = 12;

                let slidesPerView = length < perView ? length : perView;

                $('.booking-calendar-wrapper').addClass('col-lg-' + slidesPerView);
        
                const slider = new Slider($bookingCalendar, {
                   // mode: 'snap',
                    //centered: true,
                    duration: 200,
                    fade: false,
                    loop: false,
                    controls: true,
                    initial: 0,
                    //arrows: length > perView,
                    arrows: true,
                    slidesPerView: slidesPerView,
                    breakpoints: {
                        '(min-width: 577px) and (max-width: 1024px)': {
                            slidesPerView: 6,
                            //arrows: length > 6,
                            //centered: true,
                        },
                        '(max-width: 576px)': {
                            slidesPerView: 2,
                            //arrows: length > 2,
                            //centered: true,
                        },
                    },
                });
            }

            if(!details.params.dateFrom && $bookingCalendar[0]) {
                $('li[data-departuredate]').each((i, item) => {
                    if(!details.params.dateFrom) {
                        details.params.dateFrom = ! $(item).hasClass('disabled') ? $(item).attr('data-departuredate') : null;
                    }
                });
                functions.setUrlData({dateFrom: details.params.dateFrom}, false, true);
            }
    
            details.params.objectGroupId = details.$form.attr('data-objectgroupid');
            details.params.objectTypeId = details.$form.attr('data-objecttypeid');
            details.params.objectId = details.$form.attr('data-objectid');
            //details.params.dateTo = $('[value=' + details.params.dateFrom + ']').attr('data-dateto');
            details.params.dateTo = $('[data-departuredate=' + details.params.dateFrom + ']').attr('data-dateto');

            $('li[data-departuredate=' + details.params.dateFrom + ']').addClass('selected');

            const selectedDates = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-selecteddates');
            $('.selected-dates').text(selectedDates);
            const arrivalDay = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-arrivalday');
            $('.arrival-day').text(arrivalDay);
            const dataDescription = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-description');
            $('.data-description').html(dataDescription);

            const $unitDuration = $('.unit-duration');
            if($unitDuration.length) {
                let dateFrom = new Date(details.params.dateFrom);
                let dateTo = new Date(details.params.dateTo);
                let diff = new Date(dateTo.getTime() - dateFrom.getTime());
                $unitDuration.text(diff.getUTCDate());
            }

            $('li[data-departuredate]').on('click', e => {
                let $target = $(e.target);
                if(! $target.attr('data-departuredate')) {
                    $target = $target.parents('li[data-departuredate]');
                    details.params.dateFrom = $target.attr('data-departuredate');
                } else {
                    details.params.dateFrom = $target.attr('data-departuredate');
                }

                details.params.dateTo = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-dateto');

                functions.setUrlData({dateFrom: details.params.dateFrom}, false, true);

                $('li[data-departuredate]').each((i, item) => {
                    $(item).removeClass('selected'); 
                });

                $('li[data-departuredate=' + details.params.dateFrom + ']').addClass('selected');

                const selectedDates = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-selecteddates');
                $('.selected-dates').text(selectedDates);
                const arrivalDay = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-arrivalday');
                $('.arrival-day').text(arrivalDay);
                const dataDescription = $('li[data-departuredate=' + details.params.dateFrom + ']').attr('data-description');
                $('.data-description').html(dataDescription);

                if($unitDuration.length) {
                    let dateFrom = new Date(details.params.dateFrom);
                    let dateTo = new Date(details.params.dateTo);
                    let diff = new Date(dateTo.getTime() - dateFrom.getTime());
                    $unitDuration.text(diff.getUTCDate());
                }
            });
    
            /*details.$form.on('change', e => {
                functions.setUrlData(functions.getFormData($(e.target)), false, true);
                details.params.dateFrom = $(e.target).val();
                details.params.dateTo = $(e.target).find('[value=' + details.params.dateFrom + ']').attr('data-dateto');
            });*/

            details.calculateWithBasicBeds();
        }

        $('[name="guests"]').on('change', e => {
            details.params.guests = $(e.target).val();
            details.params.pricelistItemId = $(e.target).attr('data-pricelistitemid');
            details.getCalculation($(e.target).attr('data-unitid'));
        });

        //details.getCalculation();

        $(window).on('hashchange', e => {
            details.getParams();

            $('.units').css('opacity', '0');

            details.calculateWithBasicBeds();

            setTimeout(() => { $('.units').css('opacity', '1'); }, 400);

            charterBook && $('.charter-inquiry')[0] && functions.setInquiryData($('.charter-inquiry')[0]);
            
        });

        const items = functions.getCookie('cart') ? JSON.parse(functions.getCookie('cart')) : [];
        $('.cart-items-count').text(items.length);
        
        $('.add-item').on('click', e => {
            details.getParams();
            
            details.params.id = Date.now();

            details.params.unitId = $(e.target).attr('data-unitid');
            details.params.pricelistItemId = $(e.target).attr('data-pricelistitemid');
            const $item = $('.booking-excursion[data-pricelistitemid=' + details.params.pricelistItemId + ']');
            if($item[0]) {
                details.params.dateFrom = $item.attr('data-datefrom');
                details.params.dateTo = $item.attr('data-dateto');
            } else {
                //details.params.dateTo = $('[value=' + details.params.dateFrom + ']').attr('data-dateto');
                details.params.dateTo = $('[data-departuredate=' + details.params.dateFrom + ']').attr('data-dateto');
            }
            
            if(details.params.unitId) {
                let cartUnitData = cartData[details.params.unitId] || (cartData[details.params.unitId] = {});
                cartUnitData = cartData[details.params.unitId][details.params.dateFrom] || (cartData[details.params.unitId][details.params.dateFrom] = {});
                
                cartUnitData.rest = cartUnitData.rest || cartUnitData.rest == 0 ? cartUnitData.rest : cartUnitData.quantity;

                if(cartUnitData.rest > 0 || charterBook) {
                    if(details.params.objectTypeId == 11) {
                        details.params.guests = $('[name="guests"][data-pricelistitemid="' + details.params.pricelistItemId + '"]').val();
                        let persons = details.params.guests;
                        const adults = +persons.split(',')[0];
                        const children = +persons.split(',')[1];
                        persons = adults + children;
                        cartUnitData.rest -= persons;
                        cartUnitData.added += persons;
                    } else {
                        details.params.guests = $('[name="guests"][data-unitid="' + details.params.unitId + '"]').val();
                        --cartUnitData.rest;
                        ++cartUnitData.added
                    }

                    items.push(Object.assign({}, details.params));
                    $('.cart-items-count').text(items.length);
                    functions.setCookie('cart', JSON.stringify(items));
                    functions.setCookie('cartData', JSON.stringify(cartData));
                    
                    const $alert = details.params.pricelistItemId ? $('.alert[data-pricelistitemid=' + details.params.pricelistItemId + ']') : $('.alert[data-unitid=' + details.params.unitId + ']');
                    $alert.addClass('active');
                    setTimeout(() => { $alert.removeClass('active'); }, 3000);
                }

                if(cartUnitData.rest <= 0) {
                    $(e.target).attr('disabled', true);
                }

                // Google Tag Manager
                const dataCurrency = $('[data-currency]').attr('data-currency');

                const dataName = details.params.pricelistItemId 
                    ? $('.object-name').attr('data-objectname') 
                    : $('.object-name').attr('data-objectname') + ' ' + $('.unit-title[data-unitid=' + details.params.unitId + ']').attr('data-cabinname');

                let dataTotal = details.params.pricelistItemId 
                    ? $('.unit-price-total[data-pricelistitemid=' + details.params.pricelistItemId + ']').attr('data-unittotal') 
                    : $('.unit-price-total[data-unitid=' + details.params.unitId + ']').attr('data-unittotal');

                dataTotal = functions.formatMoney(dataTotal, 2, '.', '');

                dataLayer.push({ 'ecommerce': null });

                dataLayer.push({
                    'event': 'add_to_cart',
                    'analytics': 'GA4',
                    'currency': dataCurrency,
                    'ecommerce': {
                        'event_category': 'Ecommerce',
                        'items': [
                            {
                                'id': details.params.unitId,
                                'name': dataName,
                                'total': dataTotal,
                            }
                        ]
                    }
                });

                //console.log(dataLayer);
                
            }
        });

    },
    getParams() {
        $.extend(details.params, functions.getUrlData(true));
    },
    isBookable(data) {
        return data.isAvailable && data.calc.total && data.calc.status == 'Active';
    },
    calculateWithBasicBeds() {
        if(details.params.dateFrom) {
            details.params.guests = '2,0,0';
            details.params.guests = this.charterBook ? $('.input-guests').attr('data-max') + ',0,0' : details.params.guests;
            details.getCalculation().then(() => {
                $('[data-basicbeds]').each((index, item) => {
                    if($(item).attr('data-basicbeds') == 1) {
                        details.params.guests = $(item).attr('data-basicbeds') + ',0,0';
                        details.getCalculation($(item).attr('data-unitid'));
                    }
                    details.params.guests = $(item).attr('data-basicbeds') + ',0,0';
                });
            });
        } else {
            $('.input-guests-group').addClass('d-none');
            $('.unit-price').addClass('d-none');
            $('.add-item').addClass('d-none');
        }
    },
    getCalculation(unitId = null) {

        const pricelistItemId =  details.params.pricelistItemId;
        const dateFrom = details.params.dateFrom;
        const guests = details.params.guests;
        
        if(
            (details.params.objectId || unitId) 
            && guests 
            && details.params.dateFrom 
            && details.params.dateTo 
            && details.params.objectGroupId
        ) {

            return $.getJSON(
                location.protocol
                + '//'
                + location.hostname
                + '/services/calc/'
                + (unitId ? '?unitId=' + unitId : '?objectId=' + details.params.objectId)
                + '&guests=' + guests
                + '&dateFrom=' + details.params.dateFrom
                + '&dateTo=' + details.params.dateTo
                + '&objectGroupId=' + details.params.objectGroupId
                + (details.params.exchangeRate ? '&exchangeRate=' + details.params.exchangeRate : null)
                + '&language=' + functions.getLang()
            )
            .done($.proxy(function (pricelistItemId, dateFrom, response) {
                
                if(response.status) {
                    const data = response.data || [];

                    Object.entries(data).forEach(unit => {
                        const unitId = unit[0];
                        const unitData = unit[1];

                        $('.unit-price-total').each(function (index, item) {
                            const isExcursion = $('.unit-price-total[data-pricelistitemid='  + pricelistItemId + ']')[0];
                            //const isCruise = $('.unit-price-total[data-unitid='+ unitId + ']')[0];

                            const 
                                $sold = isExcursion 
                                        ? $('.unit-sold[data-pricelistitemid=' + pricelistItemId + ']') 
                                        : $('.unit-sold[data-unitid=' + unitId + ']'),
                                $unitPrice = isExcursion 
                                        ? $('.unit-price[data-pricelistitemid=' + pricelistItemId + ']') 
                                        : $('.unit-price[data-unitid=' + unitId + ']'),
                                $booking = isExcursion 
                                        ? $('.add-item[data-pricelistitemid=' + pricelistItemId + ']') 
                                        : $('.add-item[data-unitid=' + unitId + ']'),
                                $guests = isExcursion 
                                        ? $('.input-guests-group[data-pricelistitemid=' + pricelistItemId + ']') 
                                        : $('.input-guests-group[data-unitid=' + unitId + ']');

                            if(details.isBookable(unitData)) {

                                $('.unit-ship[data-unitid=' + unitId + ']').parents('.ship-units').removeClass('d-none');
                                $('.unit-ship[data-unitid=' + unitId + ']').removeClass('d-none');
    
                                const unitCalc = unitData.calc;
                                const calcItems = unitCalc.items;
                                let priceOld = 0.00;
                                let discountTitle = null;

                                calcItems.forEach(item => {
                                    if(item.itemType != 'discount' && !item.optional && !item.onSpot) {
                                        priceOld += item.itemTotal;
                                    }
                                    if(item.itemType == 'discount') {
                                        discountTitle = item.itemTitle;
                                        if(item.data && item.data.text) {
                                            discountTitle = item.data.text;
                                        }
                                    }
                                });

                                if(!cartData[unitId]) {
                                    cartData[unitId] = {};
                                }

                                if(cartData[unitId] && cartData[unitId][dateFrom]) {
                                    const cartDataItem = cartData[unitId][dateFrom];
                                    cartDataItem.quantity = unitData.quantity;
                                    if(cartDataItem.added) {
                                        cartDataItem.rest = cartDataItem.quantity - cartDataItem.added;
                                    }
                                    const isDisabled = cartData[unitId][dateFrom].rest <= 0  && cartDataItem.quantity <= cartDataItem.added;
                                    if(isExcursion) {
                                        $('.add-item[data-pricelistitemid=' + pricelistItemId + ']').attr('disabled', isDisabled);
                                    } else {
                                        $('.add-item[data-unitid=' + unitId + ']').attr('disabled', isDisabled);
                                    }
                                } else {
                                    cartData[unitId][dateFrom] = { quantity: unitData.quantity };
                                }

                                functions.setCookie('cartData', JSON.stringify(cartData));

                                if(!isExcursion && $('.unit-quantity-total[data-unitid=' + unitId + ']')[0] && unitData.quantity < 5) {
                                    $('.unit-quantity-total[data-unitid=' + unitId + ']').text(unitData.quantity);
                                    $('.unit-quantity[data-unitid=' + unitId + ']').removeClass('d-none');
                                } else {
                                    $('.unit-quantity[data-unitid=' + unitId + ']').addClass('d-none');
                                }

                                $sold.addClass('d-none');
                                $unitPrice.removeClass('d-none');
                                $booking.removeClass('d-none');
                                $guests.removeClass('d-none');
                                
                                const $item = isExcursion 
                                        ? $('.unit-price-total[data-pricelistitemid='  + pricelistItemId + ']')
                                        : $('.unit-price-total[data-unitid='+ unitId + ']');

                                $item.text(functions.formatMoney(unitCalc.total, 0, '.', ',')  + ' ' + unitCalc.currencySymbol);
                                $item.attr('data-unittotal', unitCalc.total);


                                $('.unit-price-total-converted[data-unitid='+ unitId + ']').text(functions.formatMoney(unitCalc.totalEUR, 0, '.', ','));
                                $('.dual-price[data-unitid=' + unitId + ']').removeClass('d-none');

                                if(priceOld && discountTitle) {
                                    $('.unit-price-old[data-unitid='+ unitId + ']').text(functions.formatMoney(priceOld, 0, '.', ',') + ' ' + unitCalc.currencySymbol);
                                    $('.unit-discount-title[data-unitid='+ unitId + ']').text('*' + discountTitle);
                                } else {
                                    $('.unit-price-old[data-unitid='+ unitId + ']').text('');
                                    $('.unit-discount-title[data-unitid='+ unitId + ']').text('');
                                }
                                
                            } else {

                                if(!isExcursion && $('.unit-quantity[data-unitid=' + unitId + ']')[0]) {
                                    $('.unit-quantity[data-unitid=' + unitId + ']').addClass('d-none');
                                }

                                if(unitData.calc.total == 0) {
                                    $('.unit-ship[data-unitid=' + unitId + ']').parents('.ship-units').addClass('d-none');
                                    $('.unit-ship[data-unitid=' + unitId + ']').addClass('d-none');
                                } else {
                                    $('.unit-ship[data-unitid=' + unitId + ']').parents('.ship-units').removeClass('d-none');
                                    $('.unit-ship[data-unitid=' + unitId + ']').removeClass('d-none');
                                }

                                $('.dual-price[data-unitid=' + unitId + ']').addClass('d-none');
                                $booking.addClass('d-none');
                                $guests.addClass('d-none');
                                $unitPrice.addClass('d-none');
                                $sold.removeClass('d-none');
                            }
                        });
                    });

                    $('.ship-units').each((index, elem) => {

                        let showShip = false;

                        $(elem).find('.unit-ship').each((index, unit) => {

                            if(! showShip && ! $(unit).hasClass('d-none')) {
                                $(elem).removeClass('d-none');
                                showShip = true;
                            }
                        });

                    });
                    
                }
            }, window, pricelistItemId, dateFrom)).fail(function (error) {
                //console.log(error);
            });
        } else {
            return $.Deferred().promise();
        }
    }
}