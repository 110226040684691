import functions from '../../functions';

if ( window.wp && window.wp.ajax ) {

    const { registerBlockType } = window.wp.blocks;
    
    const { 
        InnerBlocks,
        InspectorControls,
        MediaUpload
    } = window.wp.editor;

    const { 
        PanelBody, 
        IconButton,
    } = window.wp.components;

    registerBlockType('custom-gutenberg/container', {
        // built-in attributes
        title: 'Container',
        description: 'Custom Container',
        icon: '',
        category: 'custom-containers',
        example: {},

        // custom attributes
        attributes: {
            imageUrl: {
                type: 'string',
                default: ''
            }
        },

        // built-in functions
        edit({ attributes, setAttributes, className }) {

            const {
                classes,
                imageUrl
            } = attributes;

            className = functions.getClassNames(className);
            if(classes != className) setAttributes({classes: className});

            // custom functions
            function onSelectImage (imageUrl) {
                setAttributes({imageUrl: imageUrl.sizes.full.url});
            }

            return ([
                <InspectorControls style={ { marginBottom: '40px' } }>
                    <PanelBody title={ 'Settings' }>
                        <MediaUpload 
                            onSelect={ onSelectImage }
                            type="image"
                            value={ imageUrl } 
                            render={ ( { open } ) => (
                                <IconButton
                                    onClick={ open }
                                    icon="upload"
                                    classNames="editor-media-placeholder__button is-button is-default is-large">
                                    Select Background Image
                                </IconButton>
                            ) } />
                    </PanelBody>
                </InspectorControls>
                ,
                <div className={ "container " + (classes || '') } style={{ backgroundImage: `url(${imageUrl})` }}>
                    <InnerBlocks />
                </div>
            ]);
        },

        save({ attributes }) {

            const {
                classes,
                imageUrl
            } = attributes;
            
            return (
                <div className={ "container " + (classes || '') } data-background-image={ imageUrl }>
                    <InnerBlocks.Content />
                </div>
            );
        },
    });
}