import functions from './functions.js';

$(() => {
    const $form = $('.newsletter-form');
    const form = $form[0];

    if(! form) return;

    $form.on('submit', e => {
        e.preventDefault();

        $('.contact-btn').addClass('d-none');
        $('.contact-spinner').removeClass('d-none');
        $('.contact-spinner').addClass('d-flex');

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        //params['newsletter'] = false;
        //params['email'] = $form[0].email.value;
        params['language'] = functions.getLang();

        $.post('/services/mailchimp/', params).then(response => {

            $('#contact-modal').modal('show');
            $(".response-output").html(response.data);

            $('.contact-btn').removeClass('d-none');
            $('.contact-spinner').removeClass('d-flex');
            $('.contact-spinner').addClass('d-none');

            setTimeout(() => { $('#contact-modal').modal('hide') }, 5000);

            if (response.status == true) {
                form.reset();
            }
            
        });

        /*grecaptcha.ready(function () {
            grecaptcha.execute('6Lf9hl0aAAAAAL-eQLlwbHIHJ2Ha2zIbr6Q1MSNB', { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                $.post('/services/contact/', params).then(response => {
                    
                    $('#contact-modal').modal('show');
                    $(".response-output").html(response.data);

                    $('.contact-btn').removeClass('d-none');
                    $('.contact-spinner').removeClass('d-flex');
                    $('.contact-spinner').addClass('d-none');

                    setTimeout(() => { $('#contact-modal').modal('hide') }, 5000);

                    if (response.status == true) {
                        form.reset();
                    }
                });
            });
        });*/
    });

});