import functions from './functions.js';
//import Slider from './Slider.js';
//import lozad from 'lozad';
import inputPopover from "./input-popover.js";
import inputGuests from "./input-guests.js";

$(function() {

    inputPopover.apply();
    inputGuests.apply();

    window.cart = JSON.parse(functions.getCookie('cart') || '{}');
    window.cartData = JSON.parse(functions.getCookie('cartData') || '{}');
    window.cartData = cart.length > 0 ? window.cartData : JSON.parse('{}');

    //let cartItems = functions.getCookie('cart') ? JSON.parse(functions.getCookie('cart')) : [];
    $('.cart-items-count').text((cart.length ? cart.length : 0));

    document.addEventListener('scroll', e => {
        if(window.scrollY > 20) {
            $('.main-menu').addClass('position-fixed');
        } else {
            $('.main-menu').removeClass('position-fixed');
        }
    });

    /*if(! functions.getCookie('displayBenefitsModal')) {
        setTimeout(() => {
            $('#benefitsModal').modal('show');
            functions.setCookie('displayBenefitsModal', true, 1);
        }, 10000);
    }*/

    // global datepicker init with automatic "dateTo" set if provided
    let displayDate = new Date('2021-01-01');
    let now = functions.cleanDate(new Date);
    if ( now > displayDate ) {
        displayDate = now;
    }

    $('.datepicker').each((i, elem) => {
        const $datepicker = $(elem);
        const minDate = $datepicker.attr('data-min');
        const maxDate = $datepicker.attr('data-max');
        const defaultDate = $datepicker.attr('data-defaultDate') ? $datepicker.attr('data-defaultDate') : [];
        $datepicker.flatpickr({
            altInput: true,
            wrap: true,
            altFormat: "j.m.Y",
            dateFormat: "Y-m-d",
            minDate: minDate ? minDate : now,
            maxDate: maxDate ? maxDate : null,
            defaultDate: defaultDate,
            disableMobile: true,
            prevArrow: '<i class="la la-angle-left pr-2"></i>',
            nextArrow: '<i class="la la-angle-right pl-2"></i>',
            onChange(selectedDates, dateStr, instance) {

                const $first = $(instance.input);
                // prevent other logic if second datepicker is triggered by first
                if (instance._openByFirst) {
                    delete instance._openByFirst;
                    return;
                }
                
                if ($first.attr('data-firstDate')) {
                    const $second = $first.closest('form').find('[data-secondDate]').closest('.datepicker');
                    if ($second.length) {

                        var firstDate = new Date(selectedDates[0]);
                        firstDate.setDate(firstDate.getDate() + 1);
                        
                        var secondInstance = $second[0]._flatpickr;
                        // check if firstDate is invalid
                        if (firstDate.getTime() === firstDate.getTime()) {
                            secondInstance._openByFirst = true;
                            secondInstance.set('minDate', firstDate);
                            if (!secondInstance.selectedDates[0]) {
                                firstDate.setDate(firstDate.getDate() + 6);
                                secondInstance.setDate(firstDate);
                            }
                            secondInstance.open();
                            $(secondInstance.altInput).focus();
                        }
                        else if (secondInstance.selectedDates[0]) {
                            secondInstance.clear();
                        }
                    }
                }
                // check if second date is cleared
                else if ($first.attr('data-secondDate') && !selectedDates[0]) {
                    $first.closest('form').find('[data-firstDate]').closest('.datepicker')[0]._flatpickr.clear();
                }
            },
            onOpen(selectedDates, dateStr, instance) {
                if (instance.input.getAttribute('data-secondDate')) {
                    const $first = $(instance.input).closest('form').find('[data-firstDate]').closest('.datepicker');
                    if ($first.length) {
                        const firstInstance = $first[0]._flatpickr;
                        if (!firstInstance.input.value) {
                            instance.close();
                            firstInstance.open();
                            firstInstance.altInput.focus();
                            return;
                        }
                    }
                }
                !dateStr && instance.jumpToDate(minDate ? minDate : displayDate);
            }
        });
        // to style clear toggler
        $datepicker.on('change', e => requestAnimationFrame(() => $(e.target).parent().toggleClass('datepicker-value', !!e.target.value)));
    });
    
    // menu links active
    const path = location.pathname.replace(/\/$/, '');
    $('.main-header [href], footer [href]').each((i, link) => {
        let href = link.getAttribute('href');
        if ( href && href != '#' ) {
            href = href.split('?')[0].replace(/\/$/, '');
            if ( path === href ) {
                $(link).addClass('active').closest('.nav-item').addClass('active');
            }
        }
    });

    /* gallery */
    const lg = {};
    window.showGallery = (index, id) => {
        //console.log(index, id)
        index = +index || 0;
        if ( lg[id] ) {
            lg[id].index = index;
            lg[id].build(index);
        } else {
            let $elem = $('[data-images-' + id + ']');
            lg[id] = $elem.lightGallery({
                dynamic: true,
                thumbnail: true,
                toogleThumb: false,
                thumbWidth: 130,
                thumbMargin: 10,
                download: false,
                preload: 2,
                index: index,
                dynamicEl: JSON.parse($elem.attr('data-images-' + id))
            }).data('lightGallery');
        }
    };
    $('[data-gallery-id]').on('click', function (e) {
        e.preventDefault();
        let galleryId = $(this).attr('data-gallery-id');
        galleryId && showGallery($(this).attr('data-index'), galleryId);
    });
    /* end gallery */

    // mobile menu handling
    (() => {
        const $header = $('.main-header');
        const $collapser = $('#main-menu');

        // ensure navbar toggler also open closes main-menu
        $collapser
            .on('show.bs.collapse', () => {
                document.body.classList.add('modal-transition');
                document.body.classList.add('modal-active');
            })
            .on('hide.bs.collapse', () => {
                document.body.classList.remove('modal-active');
            })
            .on('hidden.bs.collapse', () => {
                document.body.classList.remove('modal-transition');
            });

        // click on "overlay" closes menu
        $header.find('.header-overlay').on('click', () => {
            $collapser.collapse('hide');
        });
    })();


    // scrool to element handling
    $(document).on('click', '[data-scroll-to]', e => {
        e.preventDefault();
        const $this = $(e.target);
        let scrollTo = $this.attr('data-scroll-to').split(',');
        functions.scrollToElement(scrollTo[0], scrollTo[1] || 0);
    });
    
   
    // button checkbox
    $('.btn-checkbox').on('click', e => {
        let input = $(e.target).prev()[0];
        input.checked = !input.checked;
    });

    $('#cookie-bar-accept').on('click', function (e) {
        functions.setCookie('cookie_usage', 'accepted');
        $("#cookie-bar").addClass('d-none');
    });


    /*$('.currency').on('click', function (e) {
        functions.setCookie('currency', $(this).data('currency'));
        location.reload();
    });*/

    $('.currency-change').on('click', function (e) {
        functions.setCookie('currencyIdTo', e.target.id);
        location.reload();
    });

    $(window).on('load', () => {
        const $body = $('body');
        const checkHeader = () => {
            $body.toggleClass('body-scrolled', window.scrollY > 100);
        }
        let timer = 0;
        $(window).on('scroll', () => {
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(checkHeader);
        });
        checkHeader();
    });

    const videoStella = document.getElementById("video_ship");
    if(videoStella) {
        const observer = new IntersectionObserver(function (intersection) {
            if ( intersection[0].isIntersecting ) {
                videoStella.pause();
            } else {
                if ( videoStella.getAttribute('data-loaded') ) {
                    videoStella.play();
                }
            }
        }, { threshold: 0.5 });
    }
   
    $('[data-video-gallery]').on('click', function (e) {
        e.preventDefault();
        $(this).lightGallery({
            dynamic: true,
            dynamicEl: [{ src: $(this).attr('data-src') }],
            videoMaxWidth: '75%',
            counter: false,
            addClass: 'video-gallery'
        });
    });

}); 