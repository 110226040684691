import functions from "./functions.js";

$(function () {

    const $form = $('.form-contact');
    const form = $form[0];

    const lang = functions.getLang();
    let output = '';

    if (!form) {
        return;
    }

    $form.on('submit', e => {
        e.preventDefault();

        $('.contact-btn').addClass('d-none');
        $('.contact-spinner').removeClass('d-none');
        $('.contact-spinner').addClass('d-flex');

        let params = {};
        $.each($form.serializeArray(), function (i, field) {
            params[field.name] = field.value;
        });

        params['language'] = functions.getLang();

        //$output.html('').addClass('d-none');
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lf9hl0aAAAAAL-eQLlwbHIHJ2Ha2zIbr6Q1MSNB', { action: 'contact' }).then(function (token) {
                params['gRecaptchaToken'] = token;
                params = functions.getDataForUrl(params);
                $.post('/services/contact/', params).then(response => {
                    
                    $('#contact-modal').modal('show');
                    $(".response-output").html(response.data);

                    $('.contact-btn').removeClass('d-none');
                    $('.contact-spinner').removeClass('d-flex');
                    $('.contact-spinner').addClass('d-none');

                    setTimeout(() => { $('#contact-modal').modal('hide') }, 4000);

                    if (response.status == true) {
                        form.reset();
                    }
                });
            });
        });

    });

});