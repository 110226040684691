import functions from "./functions.js";

// Google Tag Manager
$(function() {

    $('[data-analytics-event="checkout"]').on('click', e => {
        const units = $('.unit-title');
        let items = [];
        let total = $('[data-carttotal]').attr('data-carttotal');
        const dataCurrency = $('[data-currency]').attr('data-currency');

        units.each((index, unit) => {
            const $unit = $(unit);
            const unitId = $unit.attr('data-unitid');
            const unitName = $('.unit-title').attr('data-cabinname');
            const objectName = $('.object-name').attr('data-objectname');
            // old: Cruise Adriatic Discovery A - Cristal Cabin for 2 persons
            // new: Adriatic Inspiration B Fenix - Kabina za 2 osobe Donja paluba
            const name = objectName + ' ' + unitName;
            let unitTotal = $('.item-total-amount[data-unitid=' + unitId + ']').attr('data-itemtotal');
            unitTotal = functions.formatMoney(unitTotal, 2, '.', '');

            items.push({
                'id': unitId,
                'name': name,
                'total': unitTotal,
            });

        });
        dataLayer.push({ 'ecommerce': null });

        dataLayer.push({
            'event': 'checkout',
            'analytics': 'GA4',
            'currency': dataCurrency,
            'total': functions.formatMoney(total, 2, '.', ''),
            'ecommerce': {
                'event_category': 'Ecommerce',
                'items': items
            }
        });

        //console.log(dataLayer);
    });

    
    // success, cancelled, error
    if($('[data-analytics-purchase]')[0]) {
        let purchase = $('[data-analytics-purchase]').attr('data-analytics-purchase');
        purchase = JSON.parse(purchase);

        dataLayer.push({ 'ecommerce': null });

        /*
        {
            "paymentStatus":true,
            "currency":"EUR",
            "total":1908,
            "items":[{
                "quantity":2,
                "total":"1908.00",
                "name":"Adriatic Inspiration B Fenix - Kabina za 2 osobe Donja paluba"}
            ]}
        */

        if(purchase.paymentStatus) {
            dataLayer.push({
                'event': 'purchase',
                'analytics': 'GA4',
                'currency': purchase.currency,
                'total': purchase.total,
                'ecommerce': {
                    'event_category': 'Ecommerce',
                    'items': purchase.items
                }
            });
        } else {
            dataLayer.push({'event': 'purchase_canceled'});
        }

        //console.log(dataLayer);
    }
});
