import './scss/style.scss';
//import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'bootstrap/js/dist/index';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/modal';
import flatpickr from "flatpickr";
import 'flatpickr/dist/l10n/hr.js';
import 'flatpickr/dist/l10n/de.js';
import 'flatpickr/dist/l10n/fr.js';
import 'lightgallery/src/js/lightgallery.js';
import 'lightgallery/modules/lg-thumbnail.js';
import 'lightgallery/modules/lg-video.js';
import 'select2';
import 'select2/dist/js/i18n/hr.js';
import lozad from 'lozad';
import functions from './js/functions.js';
import './js/globals.js';
import './js/list.js';
import './js/details.js';
import './js/booking.js';
import './js/contact.js';
import './js/inquiry.js';
import './js/newsletter.js';
import './js/analytics.js';
import './js/onboard.js';
import './js/review.js';

import './js/gutenberg/gutenberg.js';

flatpickr.localize(flatpickr.l10ns[functions.getLang()]);
flatpickr.l10ns.default.firstDayOfWeek = 1;

window.observer = lozad('.lozad', {
    loaded: el => {
       $(el).triggerHandler('lozadLoaded');
    }
});
window.observer.observe();