import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    $('.btn-finish-booking').on('click', e => {
        $('#pills-booking-tab').trigger('click');
        functions.scrollToElement($('body'));
    });

    $('.btn-empty-cart').on('click', e => {
        functions.setCookie('cart', '[]');
        location.reload();
    });

    $('.remove-item').on('click', e => {
        const $target = $(e.target).hasClass('remove-item') ? $(e.target) : $(e.target).parents('.remove-item');
        const id = +$target.attr('data-index');
        let cart = functions.getCookie('cart');
        cart = JSON.parse(cart);

        cart.forEach((item, index) => {
            if(item['id'] == id) {
                const cartItem = cart[index];
                const cartDataItem = cartData[cartItem.unitId][cartItem.dateFrom];

                if(cartItem.objectTypeId == 11) {
                    let persons = cartItem.guests;
                    const adults = +persons.split(',')[0];
                    const children = +persons.split(',')[1];
                    persons = adults + children;
                    cartDataItem.rest = cartDataItem.rest - persons;
                    cartDataItem.added = cartDataItem.added - persons;
                } else {
                    ++cartDataItem.rest;
                    --cartDataItem.added;
                }

                if(cartItem) {
                    cart.splice(index, 1);
                }

                functions.setCookie('cart', JSON.stringify(cart));
                functions.setCookie('cartData', JSON.stringify(cartData));

                location.reload();
            }
        });
    });

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        advance: null,
        params: {},
        newsletter: {},

        init() {
            booking.$form = $(".booking-form");
            booking.promoCodeData = {};
            booking.exchangeRate = $("[name='customExchangeRate']").val();
            booking.currencySymbol = $("[data-currencysymbol]").attr("data-currencysymbol");
            booking.$calculation = $(".table-calculation");

            booking.$payment = $(".payment-details");

            if (booking.$payment.length) {
                booking.advancePercent = booking.$payment.data('advance-percent');
                booking.advanceAmount = booking.$payment.data('advance-amount');
                booking.advanceAmountConverted = booking.$payment.data('advance-amount-converted');
                booking.params.paymentType = 'creditCard';
                booking.$payment.find('input[name="paymentType"]').change(function (e) {
                    booking.params.paymentType = booking.$payment.find('input[name="paymentType"]:checked').val();
                });
            }

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('tr');
                let $quantity = $row.find('select.quantity');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));

                if (booking.promoCodeData.percent) {
                    booking.applyDiscount();
                }
            });


            booking.$calculation.find('select.quantity').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));

                if (booking.promoCodeData.percent) {
                    booking.applyDiscount();
                }
            });

            booking.total();

            $(".btn-promo-code").on('click', e => {
                let params = {};
                params['language'] = functions.getLang();
                params['promoCode'] = $("input[name='promoCode']").val();
                const allowedUnitIds = [
                    //1034
                    110553,110554,110555,110556,
                    110557,110558,110559,110560,
                    110561,110562,110563,110564,110565,
                    //1035
                    110707,110708,110709,110710,110711,110712,
                    110713,110714,110715,110716,110717,110718,
                    110719,110736,110738
                ];
                // 10/05, 17/05, 24/05 i 31/05
                const allowedDates = [
                    '2025-05-10', '2025-05-17', '2025-05-24', '2025-05-31'
                ];
                let ZantiumJanSpecialAllowed = true;

                if ( params['promoCode'] == 'ZantiumJanSpecial' ) {
                    $.each(booking.calc, function(key, item) {
                        if ( allowedUnitIds.indexOf(item.unitId) == -1 ) {
                            ZantiumJanSpecialAllowed = false;
                        }
                        if ( allowedDates.indexOf(item.dateFrom) == -1 ) {
                            ZantiumJanSpecialAllowed = false;
                        }
                    });
                    if ( !ZantiumJanSpecialAllowed ) {
                        params['promoCode'] = 'zantiumjaaanspecial';
                    }
                }

                $.post('/services/promoCode/', params).then(response => {
                    $(".promo-code-output").removeClass('d-none').html(response.data);
                    if (response.status == true) {
                        booking.promoCodeData = response;
                        booking.promoCodeData.promoCode = params['promoCode'];
                        
                        $(".btn-promo-code").attr('disabled', true);
                        booking.applyDiscount();
                    }
                });
            });

            booking.$form.on('submit', e => {
                //e.preventDefault();
                
                booking.newsletter.newsletter = booking.$form.find('[name=newsletter]').is(':checked');
                if(booking.newsletter.newsletter) {
                    booking.newsletter.firstName = booking.$form.find('[name=firstName]').val();
                    booking.newsletter.lastName = booking.$form.find('[name=lastName]').val();
                    booking.newsletter.email = booking.$form.find('[name=email]').val();
                    booking.newsletter.travel = booking.$form.find('[name=newsletter]').attr('data-newslettername');
                    booking.newsletter.departure = booking.$form.find('[name=newsletter]').attr('data-newsletterdate');
    
                    //console.log(booking.newsletter);
                    $.post('/services/mailchimp/', booking.newsletter).then(response => {
                        //console.log(response);
                    }).fail(error => {
                        //console.log(error);
                        //console.log(error.responseText);
                    });
                }

                booking.submit();

            });

        },
        submit() {

            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                }
            });

            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

            functions.setCookie('cart', '[]');
            $('.cart-items-count').text('0');

        },
        applyDiscount() {

            booking.$calculation.each(function (index) {

                $(this).find(".promoDiscountItem").remove();
                let promoDiscountItem = JSON.parse($(this).find("*[data-calc]:first").attr('data-calc'));
                let promoDiscountItemId = promoDiscountItem.id + '_' + 114;

                let total = 0;
                let totalConverted = 0;
                $(this).find("*[data-calc]").each(function (index) {
                    let item = JSON.parse($(this).attr('data-calc'));
                    let quantity = item.isOptional ? $(this).find('select.quantity').val() : item.quantity;
                    if ( ! item.onSpot && quantity) {
                        total += +item.price * +quantity;
                        totalConverted += +item.priceConverted * +quantity;
                    }
                });

                $.each(booking.calc, function (key, calc) {
                    if (calc && calc.id == promoDiscountItemId) {
                        booking.calc.splice(key, 1);
                    }
                });

                let discountAmount = (-1 * (total * (booking.promoCodeData.percent/100)));
                let discountAmountConverted = (-1 * (totalConverted * (booking.promoCodeData.percent/100)));

                promoDiscountItem.id = promoDiscountItemId;
                promoDiscountItem.itemDefinitionId.id = 114;
                promoDiscountItem.itemDefinitionId.text = 'Promo';
                promoDiscountItem.itemDefinitionId.categoryId.id = 80;
                promoDiscountItem.itemDefinitionId.categoryId.key = 'discount';
                promoDiscountItem.itemDefinitionId.objectGroupId.id = 6;
                promoDiscountItem.itemDefinitionId.objectGroupId.key = 'excursions';
                promoDiscountItem.itemDefinitionId.typeId.id = 2;
                promoDiscountItem.itemDefinitionId.typeId.key = 'supplement';
                promoDiscountItem.priceIn = discountAmount;
                promoDiscountItem.price = discountAmount;
                promoDiscountItem.totalIn = discountAmount;
                promoDiscountItem.total = discountAmount;
                promoDiscountItem.priceConverted = discountAmountConverted;
                promoDiscountItem.noteHidden = booking.promoCodeData.promoCode;
                promoDiscountItem.skipCalculate = true;

                $('<tr class="promoDiscountItem" data-calc=\'' + JSON.stringify(promoDiscountItem) + '\'>\n' +
                    '        <td class="px-3">\n' +
                    '            <div class="custom-control custom-checkbox">\n' +
                    '                <input type="checkbox" class="custom-control-input" id="' + promoDiscountItem.id + '" name="' + promoDiscountItem.id + '" checked="" disabled="">\n' +
                    '                <label class="custom-control-label" for="' + promoDiscountItem.id + '"></label>\n' +
                    '            </div>\n' +
                    '        </td>\n' +
                    '        <td class="px-3">\n' +
                    '            <div>\n' +
                    '                ' + promoDiscountItem.itemDefinitionId.text + '\n' +
                    '            </div>\n' +
                    '        </td>\n' +
                    '        <td class="px-3 text-center text-nowrap d-none d-lg-table-cell">\n' +
                    '                            1\n' +
                    '                    </td>\n' +
                    '        <td class="px-3 text-right text-nowrap d-none d-lg-table-cell">\n' +
                    '            ' + functions.formatMoney(discountAmount * booking.exchangeRate, 2, ',', '.') + ' ' + booking.currencySymbol + '\n' +
                    '        </td>\n' +
                    '        <td class="px-3 text-right text-nowrap d-none d-lg-table-cell">\n' +
                    '            <span class="item-total-amount">' + functions.formatMoney(discountAmount * booking.exchangeRate, 2, ',', '.') + '</span> ' + booking.currencySymbol + '\n' +
                    '        </td>\n' +
                    '    </tr>').insertBefore($(this).find("*[data-calc]:last"));

                booking.calc.push(promoDiscountItem);
                booking.calculate($(this).find(".promoDiscountItem").find("input[type='checkbox']"));
            });

        },
        calculate($elem) {
            let $row = $elem.closest('tr');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            let id = $checkbox.attr('id');

            let $quantity = $row.find('select.quantity');
            let quantity = null;

            if ($quantity.length) {
                quantity = +$quantity.val();
            }


            $.each(booking.calc, function (key, calc) {
                if (calc.id == id) {

                    if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }

                    booking.calc[key].inCalc = checked;
                    booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total * booking.exchangeRate, 2, ',', '.'));
                }
            });

            booking.total();
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            let total = 0.00;
            let totalConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc) {
                    total += booking.itemTotal(calc) * booking.exchangeRate;
                    totalConverted += booking.itemTotalConverted(calc);
                }
            });

            $('.total-amount').html(functions.formatMoney(total, 2, ',', '.'));
            $('.dual-price').removeClass('d-none');
            $('.total-amount-converted').html(functions.formatMoney(totalConverted, 2, ',', '.'));


            if (booking.$payment.length) {
                let advance = 0;
                let remaining = 0;
                let advanceConverted = 0;
                let remainingConverted = 0;

                if (+booking.advancePercent) {
                    advance = (total * booking.advancePercent) / 100;
                    remaining = total - advance;

                    advanceConverted = (totalConverted * booking.advancePercent) / 100;
                    remainingConverted = totalConverted - advanceConverted;
                }
                else if (booking.advanceAmount) {

                    advance = +booking.advanceAmount;
                    remaining = total - advance;

                    advanceConverted = booking.advanceAmountConverted;
                    remainingConverted = totalConverted - advanceConverted;
                }

                booking.$payment.find('.advance-amount').html(functions.formatMoney(advance, 2, ',', '.'));
                booking.$payment.find('.remaining-amount').html(functions.formatMoney(remaining, 2, ',', '.'));

                booking.$payment.find('.advance-converted').html(functions.formatMoney(advanceConverted, 2, ',', '.'));
                booking.$payment.find('.rest-converted').html(functions.formatMoney(remainingConverted, 2, ',', '.'));

            }
        }
    }

    booking.init();
});